export const productMenu = {
	defaultMenuName: "Default Menu",
	manageMenu: "Menu management",
	manageBars: "Bars",
	manageProducts: "Product management",
	newMenu: "New menu",
	createNewMenu:
		"Create, edit, and manage menus with status, activating or deactivating as needed.",
	requiredField: "Required Field",
	menuName: "Duplicate Menu Name",
	duplicateMenu: "Duplicate Menu",
	editMenu: "Edit Menu",
	closeAction: "Close",
	addProducts: "Add Products",
	newMenuName: "Menu Name",
	duplicateMenuError:
		"A menu of this type is already active. Only one active menu per type is allowed.",
	menus: "Menus",
	status: "Status",
	type: "Type",
	createFirstMenu: "Create your first menu now",
	createMenuInstructions: "Create your first menu now and link products and prices.",
	createMenu: "Create Menu",
	duplicate: "Duplicate",
	delete: "Delete",
	edit: "Edit",
	deleteMenu: "Delete Menu",
	confirmDelete:
		"Do you want to delete {{menuName}}? All information will be deleted, and this action cannot be undone.",
	active: "Active",
	inactive: "Inactive",
	confirmDuplicateMenu:
		"Do you want to duplicate {{menuName}}? All information in the replica will be identical to the original, but it will not be linked to any area.",
	menuType: {
		normal: "Normal",
		delivery: "Delivery",
		dineIn: "Table Order",
		hawker: "Hawker",
		grabAndGo: "Grab and Go",
		onlineFicha: "Online Receipt",
		pickUp: "Pick Up",
		deliveryByMarketplace: "Restaurant Delivery",
		onlineTab: "Online Order",
		viewMenu: "Digital Menu",
		deliveryIntegrated: "Delivery integrated",
	},
	activateMenu: "Activate Menu",
	deactivateMenu: "Deactivate Menu",
	importProducts: "From Product Import",
	importWithSheet:
		"Use spreadsheet to automatically import new products for the new menu",
	linkExistingProducts: "Link Existing Products",
	selectImportedProducts: "Select already imported products to link them to the new menu",
	importCategoriesProducts:
		"Categories and products will be imported and created. If the product already exists, it will be duplicated in this import.",
	emptyText: {
		title: "There's nothing here yet",
		description: "Start by linking categories, then add products to the menu.",
	},
	menuTypeLabel: "Menu Type",
	downloadSampleSheet: "Click here to download the sample spreadsheet",
	clickToAddSheet: "Click to add spreadsheet",
	importSheet: "Import Spreadsheet",
	importProductsTitle: "Import Products",
	emptyRowsWarning: "Your spreadsheet contains empty rows",
	linkProductsToMenu: "Link products to the menu",
	noProductFound: "No products found",
	typeToSearch: "Type to search",
	enterNewMenuName: "Enter the name of the new menu",
	selectMenuType: "Select the menu type",
	addProductsCount: "Add {{products}} products",
	selectProductsToLink: "Select the products you want to link to the new menu",
	loadingProducts: "Loading products",
	fileLoadError:
		"We couldn't load your file. Please confirm if the file was saved correctly and try again",
	loadingSheet: "Loading spreadsheet",
	invalidRowsError: "Invalid rows, check the following columns: {{rows}}",
	linkProductToMenu: "Link product",
	allCategories: "All categories",
	createProduct: "Create Product",
	confirmRemoveProdutFromMenu:
		"Do you want to unlink {{productName}} from the menu? If there are edited price information, you will lose them. If you only want to deactivate the product from the menu, you can click on the status switch in the menu product table.",
	removeProductFromMenu: "Unlink product from the menu",
	confirmRemoveProductFromMenuLabel: "Unlink product",
	revertProductPriceTitle: "Undo Price Change",
	confirmRevertProductPriceDescription:
		"By confirming this action, you will revert to the product's original price, discarding the previously made change. The product price is determined in the 'Product Management' section.",
	editProductTitle: "Edit {{productName}}",
	category: "Category",
	price: "Price",
	saveChanges: "Save Changes",
	editMenuProduct: "Edit product in the menu",
	yes: "Yes",
	no: "No",
	positivePriceErrorMessage: "Price must be a positive value",
	viewProductPage: "View Product Page",
	product: "Product",
	mountable: "Mountable",
	searchProduct: "Search Product",
	importWithSheetToMenu:
		"Use spreadsheet to automatically import new products for the menu",
	createProductFromScratch: "Create product from scratch",
	createNewProduct: "Create a new product by filling in all the necessary data",
	selectProductsToLinkToMenu: "Select the products you want to link to the menu",
	addProductsToMenu: "Add products to the menu",
	emptyMenu: "Empty Menu",
	addProductsToStartUsingIt: "Add products to start using it",
	sku: "SKU",
	nameMaxLength: "Limit of {{maxLength}} characters exceeded",
	menuCreated: "Menu created successfully",
	menuUpdated: "Menu updated successfully",
	menuRemoved: "Menu removed successfully",
	menuDuplicated: "Menu duplicated",
	menuStatusChanged: "Menu status changed",
	menuProductPriceChanged:
		"The price of {{productName}} has been successfully changed in {{menuName}}.",
	menuProductUpdated:
		"The product {{productName}} has been successfully updated in {{menuName}}.",
	menuProductRemoved:
		"The product {{productName}} has been successfully removed from {{menuName}}.",
	productsAmountAtMenu: "product qty.",
	products: "product(s)",
	filters: {
		all: "All",
		filterByName: "Filter by name",
		filterByMenuType: "Filter by menu type",
		allMenuTypes: "All menu types",
		filterByLinkedBars: "Filter by linked bars",
		activeFilters: "Active filters",
		clearAll: "Clear all filters",
		noResults: "No results found with the current filters",
		clearFilters: "Clear filters",
	},
	createMenuModal: {
		title: "Create Menu",
		menuNameLabel: "Menu Name",
		menuNamePlaceholder: "Name the menu",
	},
	editMenuModal: {
		title: "Edit Menu",
	},
	bars: "Bars",
	dashboard: {
		productCount: "Product qty.",
		categoryCount: "Categories",
		barCount: "Bars",
	},
	linkProductsDrawer: {
		newlySelected: "Selected",
		addAllProducts: "Add All Products",
		linkProducts: "Link Products",
		title: "Link Products to Menu",
		successMessage: "Product(s) linked to menu successfully",
		typeToSearch: "Type to search",
		noProductFound: "No products found",
		product: "Product",
		sku: "SKU",
		closeAction: "Close",
		saveChanges: "Save Changes",
		selectProductsToLinkToMenu: "Select the products you want to link to the menu",
		searchProduct: "Search Product",
	},
};
