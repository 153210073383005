export const productionControl = {
	label: "Recipes and production",
	at: "at",
	attachedToModal: {
		title: "Item Attachments",
		description: "List of records to which this item is attached",
		seeDetails: "See Details",
	},
	tabs: {
		production: "Recipes and Production",
		productionHistory: "Production History",
	},
	list: {
		emptyList: "No recipes found",
		createdAt: "Created on {{date}}",
		title: "Recipes",
		subtitle: "Select the recipe you want to produce",
		searchPlaceholder: "Search for a recipe",
		links: "Links",
		linksTotal: "{{count}} products",
	},
	history: {
		table: {
			name: "Name",
			storage: "Stock",
			fiscalCode: "SKU",
			createdAt: "Creation Date",
			count: "Quantity Produced",
			cost: "Value",
			author: "Producer",
		},
		filters: {
			search: {
				label: "Search by",
				placeholder: "Name, producer, or SKU",
			},
			interval: {
				label: "Filter by period",
			},
		},
	},
	producer: "Producer",
	expectedYield: "Expected Yield",
	yield: "Yield",
	storage: "Stock",
	production: {
		title: "Produce Input",
		steps: {
			CONFIGURATION: {
				title: "Configuration",
				description: "Select the stock to begin",
			},
			PREPARE: {
				title: "Preparation",
				description: "Fill in the quantity of each ingredient and click next to proceed",
			},
			RESULT: {
				title: "Yield",
				description: "Review your production and fill in the yield to finish",
			},
		},
		fields: {
			storage: {
				label: "Stock",
				placeholder: "Select a stock",
			},
			producer: {
				label: "Producer",
			},
			inputCount: {
				label: "Quantity Used",
			},
			inputLoss: {
				label: "Loss",
			},
			output: {
				label: "Quantity Produced",
			},
		},
		statusDialog: {
			loading: {
				dialogTitle: "Production",
				title: "Producing...",
				subtitle: "We are producing your product, please wait a moment.",
			},
			success: {
				dialogTitle: "Production - Success",
				title: "Production Completed!",
				subtitle: "{{count}} of {{recipe}} entered into {{storage}} stock",
			},
			error: {
				dialogTitle: "Production - Error",
				title: "Oops, something went wrong!",
				subtitle: "We couldn't perform the production. Please try again later.",
			},
		},
		backToHome: "Back to Homepage",
		backToYield: "Back to Yield",
		ingredientsTable: {
			name: "Ingredient",
			count: "Quantity",
			loss: "Loss",
			totalLoss: "Stock Output",
		},
		outOfExpectedYield: {
			cancelText: "Edit yield",
			description:
				"Your output is significantly outside the production rule. Do you want to continue anyway?",
		},
	},
	validation: {
		numberMustBeGreaterThanZero: "The value must be greater than 0",
		numberMustBePositive: "The value must be positive",
		nameMustHaveMaxCharacters: "The name must have a maximum of 30 characters",
		skuMustHaveMaxCharacters: "The SKU must have a maximum of 60 characters",
	},
	save: {
		newTitle: "New Rule",
		editTitle: "Edit Rule",
		createAction: "Create Rule",
		addSupplyAction: "Add Input",
		detailsTitle: "Rule Details",
		productionRule: "Production Rule",
		inputsTable: {
			name: "Input",
			count: "Quantity",
			loss: "Loss",
			actions: "Actions",
		},
		form: {
			name: {
				label: "Name",
				placeholder: "Enter name",
			},
			yield: {
				label: "Rule Yield",
			},
			image: {
				label: "Photo",
				caption: "Square image or 500x500 pixels",
			},
			inputName: {
				label: "Input or product",
			},
			inputCount: {
				label: "Quantity",
			},
			inputLoss: {
				label: "Loss",
			},
			output: {
				label: "Quantity Produced",
			},
		},
	},
	deleteDialog: {
		title: "Delete Rule",
		content:
			"By confirming this action, you will permanently delete this production rule. Do you want to continue?",
	},
	actions: {
		produce: "Produce",
		delete: "Delete",
		edit: "Edit",
	},
	recipe: {
		title: "Recipe Details",
		ingredientsTitle: "Recipe and production",
		totalCost: "Total Cost",
		yield: "Yield expected",
		deleteInputDialog: {
			content:
				"By confirming this action, you will remove the item from the recipe. Do you want to continue?",
			title: "Delete Item",
		},
		multipleItems: "Items",
		singleItem: "Item",
		links: "Links",
	},
};
